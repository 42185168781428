import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./css/styles.css";
import "./css/react-tags.css";

import { AuthProvider } from "./contexts/AuthContext";
import { LocationsProvider } from "./contexts/LocationsContext";
import { StoriesProvider } from "./contexts/StoriesContext";
import { TagsProvider } from "./contexts/TagsContext";

import AccountCreate from "./pages/AccountCreate";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Locations from "./pages/Locations";
import Memories from "./pages/Memories";
import Connections from "./pages/Connections";
import Story from "./pages/Story";
import StoryEdit from "./pages/StoryEdit";
import StoryNew from "./pages/StoryNew";
import StoryMap from "./pages/StoryMap";
import PageNotFound from "./pages/PageNotFound";
import LocationEdit from "./pages/LocationEdit";
import LocationNew from "./pages/LocationNew";
import ProtectedRoute from "./pages/ProtectedRoute";
import AppLayout from "./pages/AppLayout";
import Timeline from "./pages/Timeline";
import Chat from "./pages/Chat";
import Media from "./pages/Media";
import MediaUpload from "./pages/MediaUpload";
import MediaEdit from "./pages/MediaEdit";
import AccountEdit from "./pages/AccountEdit";
import ProfilePic from "./pages/ProfilePic";
import ProfileMasthead from "./pages/ProfileMasthead";
import AccountSnapShot from "./pages/AccountSnapshot";
import AccountDetail from "./pages/AccountDetail";
import TagManager from "./pages/TagManager";

export default function App() {
  return (
    <div className="App">
      <AuthProvider>
        <LocationsProvider>
          <StoriesProvider>
            <TagsProvider>
              <BrowserRouter>
                <Routes>
                  <Route index element={<Login />} />
                  <Route path="accountcreate" element={<AccountCreate />} />
                  <Route
                    path="/app"
                    element={
                      <ProtectedRoute>
                        <AppLayout />
                      </ProtectedRoute>
                    }
                  >
                    <Route path="home" element={<Homepage />} />
                    <Route path="locations" element={<Locations />} />
                    <Route path="locations/:id" element={<LocationEdit />} />
                    <Route path="locationnew" element={<LocationNew />} />
                    <Route path="memories" element={<Memories />} />
                    <Route path="timeline" element={<Timeline />} />
                    <Route path="connections" element={<Connections />} />
                    <Route path="story" element={<Story />} />
                    <Route path="story/new" element={<StoryNew />} />
                    <Route path="story/:id" element={<StoryEdit />} />
                    <Route path="storymap/:id" element={<StoryMap />} />
                    <Route path="media" element={<Media />} />
                    <Route path="media/:id" element={<MediaEdit />} />
                    <Route path="media/upload" element={<MediaUpload />} />
                    <Route path="tags" element={<TagManager />} />
                    <Route path="chat" element={<Chat />} />
                    <Route path="account" element={<AccountDetail />} />
                    <Route
                      path="account/:screenname"
                      element={<AccountSnapShot />}
                    />
                    <Route path="accountedit" element={<AccountEdit />} />
                    <Route
                      path="accountedit/profilepic"
                      element={<ProfilePic />}
                    />
                    <Route
                      path="accountedit/profilemasthead"
                      element={<ProfileMasthead />}
                    />
                  </Route>

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </BrowserRouter>
            </TagsProvider>
          </StoriesProvider>
        </LocationsProvider>
      </AuthProvider>
    </div>
  );
}
