import { useReducer } from "react";
import { useContext, createContext, useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";

const baseUrl = process.env.REACT_APP_BASE_URL;

const StoriesContext = createContext();

const initialState = {
  stories: [],
  storyIsLoading: false,
  currentStory: {},
  error: "",
};
// Add currentTags to initialState

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return { ...state, storyIsLoading: true, error: "" };

    case "stories/loaded":
      return {
        ...state,
        storyIsLoading: false,
        stories: action.payload,
        error: "",
      };

    case "story/loaded":
      return {
        ...state,
        storyIsLoading: false,
        currentStory: action.payload,
        error: "",
      };

    case "story/created":
      // const newStoryList = state.stories.map((story) =>
      //   story.StoryID === action.payload.StoryID
      //     ? {
      //         ...state.stories.Stories,
      //         StoryID: Number(action.payload.StoryID),
      //         StartDate: action.payload.StartDate,
      //         EndDate: action.payload.EndDate,
      //         StoryTitle: action.payload.StoryTitle,
      //         StoryText: action.payload.StoryText,
      //         CustUUID: action.payload.CustUUID,
      //       }
      //     : story
      // );

      console.log(action.payload);
      return {
        ...state,
        stories: [...state.stories, action.payload[0]],
        currentStory: action.payload[0],
        error: "",
        storyIsLoading: false,
      };

    case "story/updated":
      //console.log(action.payload);
      //state.stories.map((story) => console.log("StoryID " + story.StoryID));
      const newStories = state.stories.map((story) =>
        story.StoryID === action.payload[0].StoryID
          ? {
              ...state.stories.Stories,
              StoryID: Number(action.payload[0].StoryID),
              StartDate: action.payload[0].StartDate,
              EndDate: action.payload[0].EndDate,
              StoryTitle: action.payload[0].StoryTitle,
              StoryText: action.payload[0].StoryText,
              StoryTags: action.payload[0].StoryTags,
              AccessLevel: action.payload[0].AccessLevel,
              StoryLocations: action.payload[0].StoryLocations,
              StoryMedia: action.payload[0].StoryMedia,
            }
          : story
      );
      console.log("newStories: ");
      console.log(newStories);
      return {
        ...state,
        storyIsLoading: false,
        stories: newStories, //<!--- Should be new?  Brackets?
        currentStory: action.payload,
      };

    case "story/deleted":
      return {
        ...state,
        storyIsLoading: false,
        stories: state.stories.filter(
          (story) => story.StoryID !== action.payload
        ),
        currentStory: {},
      };

    case "reset":
      return {
        stories: [],
        storyIsLoading: false,
        currentStory: {},
        error: "",
      };

    case "rejected":
      return { ...state, storyIsLoading: false, error: action.payload };
    default:
      throw new Error("unknown action type");
  }
}

function StoriesProvider({ children }) {
  const { user } = useAuth();
  const [{ stories, storyIsLoading, currentStory, error }, dispatch] =
    useReducer(reducer, initialState);

  async function fetchStories() {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/stories/?custuuid=${user.CustUUID}`);
      const data = await res.json();
      dispatch({ type: "stories/loaded", payload: data });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error loading data.",
      });
    }
  }

  useEffect(() => {
    fetchStories();
  }, [user]);

  async function getStory(StoryID) {
    if (Number(StoryID) === currentStory.StoryID) return;

    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/stories/${StoryID}/${user.CustUUID}`);
      const data = await res.json();

      dispatch({ type: "story/loaded", payload: data });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error loading story data.",
      });
    }
  }

  async function createStory(newStory) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/stories/`, {
        method: "POST",
        body: JSON.stringify(newStory),
        headers: { "Content-Type": "application/json" },
      });

      const data = await res.json();

      console.log("data " + data);
      dispatch({ type: "story/created", payload: data.story });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error adding story data.",
      });
    }
  }

  async function updateStory(theStory, StoryID) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(
        `${baseUrl}/stories/${StoryID}/${user.CustUUID}`,
        {
          method: "PUT",
          body: JSON.stringify(theStory),
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await res.json();

      dispatch({ type: "story/updated", payload: data.story });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error updating story data.",
      });
    }
  }

  async function deleteStory(StoryID) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(
        `${baseUrl}/stories/${StoryID}/${user.CustUUID}`,
        {
          method: "DELETE",
        }
      );
      const data = await res.json();

      dispatch({ type: "story/deleted", payload: StoryID });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error deleting story data.",
      });
    }
  }

  function resetStories() {
    dispatch({ type: "reset" });
  }
  return (
    <StoriesContext.Provider
      value={{
        stories,
        storyIsLoading,
        currentStory,
        error,
        getStory,
        createStory,
        updateStory,
        deleteStory,
        resetStories,
        fetchStories,
      }}
    >
      {children}
    </StoriesContext.Provider>
  );
}

function useStories() {
  const context = useContext(StoriesContext);
  if (context === undefined)
    throw new Error("StoriesContext used outside of StoriesProvider");
  return context;
}
export { StoriesProvider, useStories };
