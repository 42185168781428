import { useState } from "react";
import { useTags } from "../contexts/TagsContext";
import Spinner from "./Spinner";
import { Dialog } from "../components/Dialog";

export function TagInput({ tagName, tagID }) {
  const { updatetag, isLoading, deletetag } = useTags();

  const [tagTitle, setTagTitle] = useState(tagName);
  const [tagid, setTagId] = useState();

  const [delID, setDelID] = useState(null);
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });
  const [error, setError] = useState(null); // Add error state

  const onTagChange = (e) => {
    setTagTitle(e);
  };
  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };
  async function handleDelete(thisID) {
    setDelID(thisID);
    handleDialog("Are you sure you want to delete this Tag?", true);
  }
  const areUSureDelete = async (choose) => {
    if (choose) {
      try {
        const response = await deletetag(delID);
        if (response.message) {
          throw new Error(response.message);
          setError(response.message);
          console.log("bbb");
        }
        handleDialog("", false);
        setDelID(null);
      } catch (err) {
        setError(err.message); // Set the specific error message
        handleDialog("", false);
      }
    } else {
      handleDialog("", false);
      setDelID(null);
    }
  };

  async function saveTag(e, thisID) {
    setTagTitle(e); // Update the state
    setTagId(thisID); // Update the state
    console.log("Saving Tag " + e + " " + thisID);
    console.log("Current Tag: " + e); // Use the new value directly
    console.log("Current ID: " + thisID); // Use the new value directly
    await updatetag(e, thisID); // Use the new values directly
  }

  console.log("tagID: ", tagID); // Add this line to check the value of tagID
  if (isLoading) return <Spinner />;
  return (
    <>
      {dialog.isLoading && (
        <Dialog onDialog={areUSureDelete} message={dialog.message} />
      )}
      {error && <div className="error">{error}</div>}{" "}
      {/* Display error message */}
      <li>
        <input
          type="text"
          name="tagName"
          value={tagTitle}
          onChange={(e) => onTagChange(e.target.value)}
          onBlur={(e) => saveTag(e.target.value, tagID)}
        />
        <button
          className="BTN-W-Icon BTN-Delete"
          onClick={(e) => handleDelete(tagID)}
        ></button>
        <span>{isLoading ? <Spinner /> : " "}</span>
      </li>
    </>
  );
}
