import { useTags } from "../contexts/TagsContext";

import Spinner from "../components/Spinner";

import { TagInput } from "../components/TagInput";
function TagManager() {
  const { tags, isLoading } = useTags();
  if (isLoading) return <Spinner />;
  return (
    <>
      <section>
        <h1>Tag Manager</h1>
        <p>
          Add, Edit and Delete your Tags! Simply click on the tag you want to
          edit - and edit it!
        </p>

        <ul className="TagList">
          {tags.map((tag) => (
            <TagInput key={tag.id} tagName={tag.name} tagID={tag.id} />
          ))}
        </ul>
      </section>
    </>
  );
}

export default TagManager;
