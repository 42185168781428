import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { StoryBlockPublic } from "../components/StoryBlockPublic";

function AccountSnapShot() {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgURL = process.env.REACT_APP_BASE_IMGURL;

  const { screenname } = useParams();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserinfo] = useState([]);
  const [userstory, setUserstory] = useState([]);

  useEffect(() => {
    console.log("useEffect triggered with screenname:", screenname);

    const fetchData = async () => {
      try {
        const res = await fetch(`${baseUrl}/account/user/${screenname}`);
        const data = await res.json();
        setUserinfo(data.user[0]);
        setUserstory(data.stories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      console.log("Cleanup function called");
      // Perform any necessary cleanup here
    };
  }, [baseUrl]); // Add screenname to the dependency array

  if (loading) return <Spinner />;

  return (
    <section>
      <div className="ProfileHeader">
        <div className="ProfileMasthead">
          <img
            src={`${imgURL}/userfiles/${userInfo.CustUUID}/${userInfo.ProfileMasthead}`}
            alt={`Masthead ${userInfo.FirstName} ${userInfo.LastName}`}
          />
        </div>
        <div className="ProfileAvatar">
          <img
            src={`${imgURL}/userfiles/${userInfo.CustUUID}/${userInfo.ProfilePic}`}
            alt={`Avatar ${userInfo.FirstName} ${userInfo.LastName}`}
          />
        </div>

        <div
          className="ProfileDetails"
          onClick={() => navigate(`/app/accountedit`)}
        >
          <h1>
            {userInfo.FirstName} {userInfo.LastName}
          </h1>
          <p>
            <strong>{userInfo.Company}</strong> | <i>{userInfo.ScreenName}</i>
          </p>
        </div>
      </div>
      <div className="PublicStory">
        {userstory.map((story) => (
          <StoryBlockPublic story={story} key={story.StoryID} />
        ))}
      </div>
    </section>
  );
}

export default AccountSnapShot;
