import { TagLink } from "./TagLink";
import { MediaBlock } from "./MediaBlock";
import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { MediaDisplay } from "./MediaDisplay";
import StoryLocation from "./StoryLocation";

export function StoryBlockPublic({ story }) {
  console.log("StoryTitle " + story.StoryTitle);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseImgUrl = process.env.REACT_APP_BASE_IMGURL;
  const imgURL = baseImgUrl + "/userfiles/" + story.CustUUID;

  const [storyLocations, setStoryLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [mediaDisplay, setMediaDisplay] = useState({
    image: "",
    date: "",
    caption: "",
    isLoading: false,
  });

  function cbDate(mydate) {
    const newDate = new Date(mydate);
    let theDate = newDate.toISOString().split("T")[0];
    return theDate;
  }

  const handleMediaDisplay = (image, date, caption, isLoading) => {
    console.log("Making Popup");
    setMediaDisplay({
      image: image,
      date: date,
      caption: caption,
      isLoading: true,
    });
  };

  function closeMediaPopup() {
    setMediaDisplay({
      image: null,
      date: null,
      caption: null,
      isLoading: false,
    });
  }

  useEffect(() => {
    if (story.StoryLocations && story.StoryLocations.length > 0) {
      const storylocIDs = story.StoryLocations;
      fetch(
        `${baseUrl}/stories/locations/${story.CustUUID}?LocIds=${storylocIDs}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setStoryLocations(data);
          setLoading(false);
        });
    }
  }, []);
  if (loading) return <Spinner />;
  return (
    <section className="Story">
      <div className="StoryDate">
        <p>
          {cbDate(story.StartDate)} - {cbDate(story.EndDate)}
        </p>
      </div>

      <div className={"StoryText " + story.Style}>
        <ul className="TagList">
          Tags:{" "}
          {story.StoryTags
            ? story.StoryTags.split(",").map((tag, index) => (
                <TagLink key={index} tag={tag.trim()} />
              ))
            : ""}
        </ul>
        <h3>{story.StoryTitle}</h3>
        <div
          className="StoryContent"
          dangerouslySetInnerHTML={{ __html: story.StoryText }}
        />

        <div className="MediaList">
          {story.StoryMedia
            ? story.StoryMedia.split(",").map((file, index) => (
                <MediaBlock
                  key={file}
                  Src={imgURL}
                  Picture={file}
                  handleMediaDisplay={handleMediaDisplay}
                />
              ))
            : ""}
        </div>

        <>
          <p>{story.StoryLocations}</p>
          {storyLocations.length !== 0 && (
            <>
              <h4>Location</h4>
              {storyLocations.map((place) => (
                <StoryLocation place={place} id={place.LocID} />
              ))}
            </>
          )}
        </>

        <hr />
        {/* <p>{story.StoryMedia}</p> */}
        <div className="MediaList">
          {story.StoryMedia
            ? story.StoryMedia.split(",").map((file, index) => (
                <MediaBlock key={index} Src={imgURL} Picture={file} />
              ))
            : ""}
        </div>
      </div>

      {mediaDisplay.isLoading && (
        <MediaDisplay
          image={mediaDisplay.image}
          date={mediaDisplay.date}
          caption={mediaDisplay.caption}
          onMediaDisplay={closeMediaPopup}
        />
      )}
    </section>
  );
}
