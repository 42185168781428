import { useReducer } from "react";
import { useContext, createContext, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
//const BASE_URL = "https://bunnyapi.onrender.com";
//const BASE_URL = "http://localhost:5409";
const baseUrl = process.env.REACT_APP_BASE_URL;

const LocationsContext = createContext();

const initialState = {
  locations: [],
  isLoading: false,
  currentLocation: {},
  error: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return { ...state, isLoading: true };

    case "locations/loaded":
      return { ...state, isLoading: false, locations: action.payload };

    case "location/loaded":
      return { ...state, isLoading: false, currentLocation: action.payload };

    case "location/created":
      return {
        ...state,
        isLoading: false,
        locations: [...state.locations, action.payload[0]],
        currentLocation: action.payload[0],
      };

    case "location/updated":
      const updatedLocation = state.locations.map((location) =>
        location.LocID === action.payload.LocID
          ? {
              ...state.locations.Locations,
              LocID: Number(action.payload.LocID),
              LocType: Number(action.payload.LocType),
              LocName: action.payload.LocName,
              LocTitle: action.payload.LocTitle,
              LocAddress: action.payload.LocAddress,
              StartDate: action.payload.StartDate,
              EndDate: action.payload.EndDate,
              LocLat: action.payload.LocLat,
              LocLng: action.payload.LocLng,
              CustUUID: action.payload.CustUUID,
            }
          : location
      );

      return {
        ...state,
        isLoading: false,
        locations: updatedLocation,
        currentLocation: action.payload,
      };

    case "locationdate/updated":
      const updatedLocationDates = state.locations.map((location) => {
        const updatedLocation = action.payload.find(
          (loc) => loc.LocID === location.LocID
        );
        return updatedLocation
          ? {
              ...location,
              LocID: Number(updatedLocation.LocID),
              LocType: Number(updatedLocation.LocType),
              LocName: updatedLocation.LocName,
              LocTitle: updatedLocation.LocTitle,
              LocAddress: updatedLocation.LocAddress,
              StartDate: updatedLocation.StartDate,
              EndDate: updatedLocation.EndDate,
              LocLat: updatedLocation.LocLat,
              LocLng: updatedLocation.LocLng,
              CustUUID: updatedLocation.CustUUID,
            }
          : location;
      });

      return {
        ...state,
        isLoading: false,
        locations: updatedLocationDates,
        currentLocation: action.payload,
      };

    case "location/deleted":
      return {
        ...state,
        isLoading: false,
        locations: state.locations.filter(
          (location) => location.LocID !== action.payload
        ),
        currentLocation: {},
      };

    case "reset":
      return {
        locations: [],
        isLoading: false,
        currentLocation: {},
        error: "",
      };

    case "rejected":
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error("unknown action type");
  }
}

function LocationsProvider({ children }) {
  const { user } = useAuth();
  const [{ locations, isLoading, currentLocation, error }, dispatch] =
    useReducer(reducer, initialState);

  useEffect(
    function () {
      async function fetchLocations() {
        dispatch({ type: "loading" });
        try {
          //const res = await fetch(`${baseUrl}/locations/?custuuid=${user.uuid}`);
          const res = await fetch(
            `${baseUrl}/locations/?custuuid=${user.CustUUID}`
          );
          const data = await res.json();
          dispatch({ type: "locations/loaded", payload: data.locations });
          //console.log(data);
        } catch {
          dispatch({
            type: "rejected",
            payload: "There was an error loading data.",
          });
        }
      }
      fetchLocations();
    },
    [user]
  );

  async function getLocation(LocID) {
    if (Number(LocID) === currentLocation.LocID) return;

    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/locations/${LocID}/${user.CustUUID}`);
      const data = await res.json();

      dispatch({ type: "location/loaded", payload: data });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error loading location data.",
      });
    }
  }

  async function createLocation(newLocation) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/locations/`, {
        method: "POST",
        body: JSON.stringify(newLocation),
        headers: { "Content-Type": "application/json" },
      });
      const data = await res.json();

      dispatch({ type: "location/created", payload: data.location });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error adding location data.",
      });
    }
  }

  async function updateLocation(theLocation) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(
        `${baseUrl}/locations/${theLocation.locID}/${user.CustUUID}`,
        {
          method: "post",
          body: JSON.stringify(theLocation),
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await res.json();

      dispatch({ type: "location/updated", payload: data.location[0] });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error updating location data.",
      });
    }
  }

  async function updateLocationDates(theLocations) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(
        `${baseUrl}/stories/locations/update/${user.CustUUID}`,
        {
          method: "post",
          body: JSON.stringify(theLocations),
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await res.json();

      dispatch({ type: "locationdate/updated", payload: data.locations });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error updating location data.",
      });
    }
  }

  async function deleteLocation(LocID) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(
        `${baseUrl}/locations/${LocID}/${user.CustUUID}`,
        {
          method: "DELETE",
        }
      );

      dispatch({ type: "location/deleted", payload: LocID });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error deleting location data.",
      });
    }
  }

  function resetLocations() {
    dispatch({ type: "reset" });
  }

  return (
    <LocationsContext.Provider
      value={{
        locations,
        isLoading,
        currentLocation,
        error,
        getLocation,
        createLocation,
        updateLocation,
        updateLocationDates,
        deleteLocation,
        resetLocations,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
}

function useLocations() {
  const context = useContext(LocationsContext);
  if (context === undefined)
    throw new Error("LocationsContext used outside of LocationsProvider");
  return context;
}
export { LocationsProvider, useLocations };
