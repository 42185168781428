import { useNavigate } from "react-router-dom";

export function MediaLink({ Src, Picture, FileID, DateCreated, Caption }) {
  const navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    navigate(`/app/media/${FileID}`);
  }

  // Format the date to YYYY-MM-DD
  const formattedDate = new Date(DateCreated).toISOString().split("T")[0];

  return (
    <div className="MediaItem">
      {/* <img src={Picture} alt={id} /> */}
      <img src={`${Src}/${Picture}`} />

      <em>{formattedDate}</em>
      <p>{Caption}</p>

      <div className="Controls">
        <button
          className="Gear"
          title="Edit Media Settings"
          onClick={handleClick}
        ></button>
      </div>
    </div>
  );
}
