export function MediaDisplay({ image, caption, date, onMediaDisplay }) {
  return (
    <div className="MediaDisplay" onClick={() => onMediaDisplay(false)}>
      <div className="MediaDisplayText" onClick={(e) => e.stopPropagation()}>
        <img src={`${image}`} alt="description" />
        <p>{date}</p>
        <p>{caption}</p>
      </div>
    </div>
  );
}
