export function MediaBlock({ Src, Picture, handleMediaDisplay }) {
  const image = `${Src}/${Picture}`;
  const date = "2025-01-02"; // Example date, replace with actual date
  const caption = "Example caption"; // Example caption, replace with actual caption
  const isLoading = false; // Example loading state, replace with actual state

  return (
    <div className="MediaItem">
      <img
        src={image}
        alt="desc"
        onClick={(e) => handleMediaDisplay(image, date, caption, isLoading)}
      />
    </div>
  );
}
