import { Chart } from "react-google-charts";
import { useStories } from "../contexts/StoriesContext";
import Spinner from "../components/Spinner";

function Timeline() {
  const { stories, isLoading } = useStories();

  const columns = [
    { type: "string", id: "Title" },
    { type: "string", id: "Title" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
  ];

  // function cbDate(mydate) {
  //   const newDate = new Date(mydate);
  //   let theDate = newDate.toISOString().split("T")[0];
  //   return theDate;
  // }

  const storyRows = stories.map((story) => [
    story.StoryTitle,
    story.StoryTitle,
    new Date(story.StartDate),
    new Date(story.EndDate),
  ]);
  console.log(storyRows);

  const data = [columns, ...storyRows];

  const options = {
    colors: ["#427789", "#182a38", "#e79c45", "#a72000", "#369636"],
    timeline: {
      groupByRowLabel: true,
      rowLabelStyle: { fontName: "Helvetica", fontSize: 18, color: "#427789" },
      barLabelStyle: { fontName: "Helvetica", fontSize: 18 },
    },
  };
  if (isLoading) return <Spinner />;
  return (
    <>
      <section>
        <h1>Timeline</h1>
        <div className="ChartDiv">
          <Chart
            chartType="Timeline"
            data={data}
            width="100%"
            height="100vh"
            options={options}
          />
        </div>
      </section>
    </>
  );
}

export default Timeline;
