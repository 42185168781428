import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStories } from "../contexts/StoriesContext";

import { Dialog } from "../components/Dialog";

function MediaEdit() {
  const { stories, isLoading, resetStories, fetchStories } = useStories();

  const navigate = useNavigate();
  const { user } = useAuth();
  const params = useParams();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseImgUrl = process.env.REACT_APP_BASE_IMGURL;
  const imgURL = baseImgUrl + "/userfiles/" + user.CustUUID;

  const [loading, setLoading] = useState(true);
  const [mediaData, setMediaData] = useState([]);

  const [delID, setDelID] = useState(null);
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });

  const [error, setError] = useState("");

  const [dateCreated, setDateCreated] = useState();
  const [caption, setCaption] = useState();

  const [selectedStoryOption, setSelectedStoryOption] = useState("");
  const [mediaStories, setMediaStories] = useState([]);

  const handleStoryChange = (event) => {
    setSelectedStoryOption(event.target.value);
  };

  const handleCaptionChange = (e) => {
    setCaption(e);
  };

  const handleAssignStory = () => {
    // Call BaseURL - send Media Assignment
    console.log(user.CustUUID);
    console.log(mediaData.FileID);
    console.log(selectedStoryOption);
    ///:custuuid/:fileid/:storyid
    fetch(
      `${baseUrl}/media/${user.CustUUID}/${params.id}/${selectedStoryOption}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMediaData(data);
        assignStoryTitles(data.StoryIDs);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(`${baseUrl}/media/${user.CustUUID}/${params.id}`)
      .then((res) => res.json())
      .then((data) => {
        setMediaData(data);
        setCaption(data.Caption);
        setDateCreated(data.DateCreated);
        assignStoryTitles(data.StoryIDs);
        setLoading(false);
      });
  }, []);

  const assignStoryTitles = (ids) => {
    console.log(ids);
    fetch(
      `${baseUrl}/media/${user.CustUUID}/${params.id}/stories?StoryIDs=${ids}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMediaStories(data);
        setLoading(false);
      });
  };

  function handleReset() {
    console.log("Resetting Stories...");
    resetStories();
    fetchStories();
  }

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };
  async function handleDelete(thisID) {
    setDelID(thisID);
    handleDialog(
      "Are you sure you want to Remove Media from this Story?",
      true
    );
  }

  async function removeStory(thisID) {
    try {
      const response = await fetch(
        `${baseUrl}/media/${user.CustUUID}/${params.id}/${thisID}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      //const data = await response.json();

      setLoading(false);
    } catch (error) {
      // Handle the error appropriately, e.g., show an error message to the user
      setError("An error occurred while removing story.");
      setLoading(false);
    }
    handleReset();
    navigate(`/app/media`);
  }

  const areUSureDelete = async (choose) => {
    if (choose) {
      try {
        const response = await removeStory(delID);
        if (response.message) {
          throw new Error(response.message);
          setError(response.message);
        }
        handleDialog("", false);
        setDelID(null);
      } catch (err) {
        setError(err.message); // Set the specific error message
        handleDialog("", false);
      }
    } else {
      handleDialog("", false);
      setDelID(null);
    }
  };

  async function handleSaveMedia(e) {
    e.preventDefault();

    if (!caption || !dateCreated) {
      // Display an error message to the user
      setError("Caption and Date Created are required.");
      return;
    }

    const editMedia = {
      Caption: caption,
      DateCreated: dateCreated,
    };
    console.log(editMedia);

    try {
      const response = await fetch(
        `${baseUrl}/media/${user.CustUUID}/${params.id}`,
        {
          method: "POST",
          body: JSON.stringify(editMedia),
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMediaStories(data);
      setCaption(data.media.Caption);
      setDateCreated(data.media.DateCreated);
      setLoading(false);
    } catch (error) {
      console.error("Error saving media:", error);
      // Handle the error appropriately, e.g., show an error message to the user
      setError("An error occurred while saving media.");
      setLoading(false);
    }

    navigate(`/app/media`);
  }

  useEffect(() => {
    if (mediaData.StoryIDs != null) {
      if (mediaData.StoryIDs.length > 0) {
        const theseStoryIDs = mediaData.StoryIDs;
        fetch(
          `${baseUrl}/media/stories/${user.CustUUID}?StoryIDs=${theseStoryIDs}`,
          {
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setMediaStories(data);
            setLoading(false);
          });
      } else {
        // Handle the case when StoryIDs is an empty array
        console.log("StoryIDs is an empty array");
        setLoading(false);
      }
    }
  }, []);

  return (
    <>
      {dialog.isLoading && (
        <Dialog onDialog={areUSureDelete} message={dialog.message} />
      )}

      <section>
        <h1>Edit Media {mediaData.FileID}</h1>
        <p>Set your photos location and date.</p>

        <div className="MediaDetail">
          {loading ? (
            "Loading..."
          ) : (
            <>
              <img src={`${imgURL}/${mediaData.FileName}`} />

              <div className="FatForm">
                {error && <div className="MSGError">{error}</div>}

                <label htmlFor="MediaCaption">Caption:</label>
                <input
                  type="text"
                  id="MediaCaption"
                  value={caption}
                  onChange={(e) => handleCaptionChange(e.target.value)}
                />

                <label htmlFor="MediaDateCreated">Date Taken:</label>
                <DatePicker
                  className="SearchDate"
                  selected={dateCreated}
                  id="MediaDateCreated"
                  onChange={(date) => setDateCreated(date)}
                  dateFormat="yyyy-MM-dd"
                  isClearable
                />

                <button className="BTN_Standard" onClick={handleSaveMedia}>
                  Save Data
                </button>
              </div>
            </>
          )}
        </div>

        <div className="FatForm">
          <label>Add to Story</label>
          <select value={selectedStoryOption} onChange={handleStoryChange}>
            <option> - Select Story -</option>
            {stories.map((story) => (
              <option key={story.StoryID} value={story.StoryID}>
                {story.StoryTitle}
              </option>
            ))}
          </select>
          <button className="BTN_Standard" onClick={handleAssignStory}>
            Assign
          </button>
          <button className="BTN_Standard" onClick={handleReset}>
            Reset Stories
          </button>

          <div className="MediaAssocs">
            {/* {mediaData.StoryIDs} */}
            <h4>Associated Stories</h4>
            {mediaStories && mediaStories.length > 0 && (
              <ul>
                {mediaStories.map((theStory) => (
                  <li key={theStory.StoryID}>
                    <span
                      onClick={() => navigate(`/app/story/${theStory.StoryID}`)}
                    >
                      {theStory.StoryTitle}
                    </span>
                    <button
                      className="BTN-W-Icon BTN-EditB"
                      onClick={() => navigate(`/app/story/${theStory.StoryID}`)}
                    ></button>
                    <button
                      className="BTN-W-Icon BTN-Delete"
                      onClick={(e) => handleDelete(theStory.StoryID)}
                    ></button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default MediaEdit;
