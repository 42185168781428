import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
function FriendSearchBlock({ friend }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgURL = process.env.REACT_APP_BASE_IMGURL;
  const [loading, setLoading] = useState(false);
  const [btnText, setbtnText] = useState("Request Friend");

  const [resMsg, setResMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorState, setErrorState] = useState(null);

  function addFriend() {
    console.log(friend.CustUUID);
    setLoading(true);

    const friendJson = {
      RequesterCustUUID: user.CustUUID,
      FriendCustUUID: friend.CustUUID,
    };

    fetch(`${baseUrl}/usersearch/`, {
      method: "POST",
      body: JSON.stringify(friendJson),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        // Log the status code
        console.log("Response status code:", res.status);
        if (res.status === 400 || res.status === 404) {
          setErrorState(res.status);
        }
        // Parse the response body as JSON
        return res.json();
      })
      .then((data) => {
        setResMsg(data.message);

        console.log(data);
        setbtnText("Requested");
        setLoading(false);
      });
  }

  useEffect(() => {
    setErrorMsg(resMsg);
  }, [{ errorState }]);

  return (
    <div>
      <p>
        {errorMsg != null && errorMsg.trim() !== "" && (
          <Message thisstyle={"MSGError"}>{errorMsg}</Message>
        )}
      </p>

      <div className="FriendSearchBox">
        <span
          style={{
            backgroundImage:
              "url(" +
              imgURL +
              "/userfiles/" +
              friend.CustUUID +
              "/" +
              friend.ProfilePic +
              ")",
          }}
        ></span>
        <h3>
          {friend.FirstName} {friend.LastName}
          <br />
          <i>{friend.ScreenName}</i>
        </h3>
        <button
          className="BTN_Secondary"
          onClick={() => navigate(`/app/account/${friend.ScreenName}`)}
        >
          View
          <br />
          Story
        </button>
        <button className="BTN_Standard" onClick={addFriend}>
          {btnText}
        </button>
      </div>
    </div>
  );
}

export default FriendSearchBlock;
