import { TagLink } from "./TagLink";
import { MediaBlock } from "./MediaBlock";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Dialog } from "../components/Dialog";
import { useStories } from "../contexts/StoriesContext";
import { MediaDisplay } from "./MediaDisplay";

import StoryLocation from "./StoryLocation";
import Spinner from "../components/Spinner";

export function StoryBlock({ story }) {
  const { deleteStory } = useStories();

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseImgUrl = process.env.REACT_APP_BASE_IMGURL;
  const imgURL = baseImgUrl + "/userfiles/" + story.CustUUID;

  const [storyLocations, setStoryLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [storyMedia, setStoryMedia] = useState([]);

  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });

  const [mediaDisplay, setMediaDisplay] = useState({
    image: "",
    date: "",
    caption: "",
    isLoading: false,
  });

  useEffect(() => {
    if (story.StoryLocations && story.StoryLocations.length > 0) {
      const storylocIDs = story.StoryLocations;
      fetch(
        `${baseUrl}/stories/locations/${story.CustUUID}?LocIds=${storylocIDs}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setStoryLocations(data);
          setStoryMedia(story.StoryMedia);
          setLoading(false);
        });
    }
  }, []);

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };

  const handleMediaDisplay = (image, date, caption, isLoading) => {
    console.log("Making Popup");
    setMediaDisplay({
      image: image,
      date: date,
      caption: caption,
      isLoading: true,
    });
  };

  function handleEdit(e) {
    e.preventDefault();
    navigate(`/app/story/${story.StoryID}`);
  }

  function handleDelete(e) {
    e.preventDefault();
    handleDialog("Are you sure you want to delete?", true);

    //navigate(`/app/story/${story.StoryID}`);
  }
  const areUSureDelete = (choose) => {
    if (choose) {
      //Dsend Del Function
      deleteStory(story.StoryID);
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };
  function closeMediaPopup() {
    setMediaDisplay({
      image: null,
      date: null,
      caption: null,
      isLoading: false,
    });
  }
  function cbDate(mydate) {
    const newDate = new Date(mydate);
    let theDate = newDate.toISOString().split("T")[0];
    return theDate;
  }
  if (loading) return <Spinner />;
  return (
    <section className="Story">
      <div className="StoryDate">
        <p>
          {cbDate(story.StartDate)} - {cbDate(story.EndDate)}
        </p>

        {story.AccessLevel === 1 && (
          <img src="/media/images/icon-access-unlocked.svg" alt="Everyone" />
        )}
        {story.AccessLevel === 2 && (
          <img src="/media/images/icon-access-friends.svg" alt="Friends" />
        )}
        {story.AccessLevel === 3 && (
          <img src="/media/images/icon-access-locked.svg" alt="No One" />
        )}
      </div>

      <div className={"StoryText " + story.Style}>
        <ul className="TagList">
          Tags:{" "}
          {story.StoryTags
            ? story.StoryTags.split(",").map((tag, index) => (
                <TagLink key={index} tag={tag.trim()} />
              ))
            : ""}
        </ul>
        <h3>{story.StoryTitle}</h3>
        <div
          className="StoryContent"
          dangerouslySetInnerHTML={{ __html: story.StoryText }}
        />

        <>
          {/* <p>{story.StoryLocations}</p> */}
          {storyLocations.length !== 0 && (
            <>
              <h4>Location</h4>
              {storyLocations.map((place) => (
                <StoryLocation place={place} id={place.LocID} />
              ))}
            </>
          )}
        </>

        <hr />
        {/* <p>{story.StoryMedia}</p> */}
        <div className="MediaList">
          {story.StoryMedia
            ? story.StoryMedia.split(",").map((file, index) => (
                <MediaBlock
                  key={file}
                  Src={imgURL}
                  Picture={file}
                  handleMediaDisplay={handleMediaDisplay}
                />
              ))
            : ""}
        </div>

        <div className="Functions">
          <button
            className="BTN_Subtle BTN_Story"
            onClick={() => navigate(`/app/storymap/${story.StoryID}`)}
          >
            Map
          </button>

          <button className="BTN_Subtle BTN_Story" onClick={handleEdit}>
            Edit
          </button>

          <button className="BTN_Subtle BTN_Story" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
      {dialog.isLoading && (
        <Dialog onDialog={areUSureDelete} message={dialog.message} />
      )}
      {mediaDisplay.isLoading && (
        <MediaDisplay
          image={mediaDisplay.image}
          date={mediaDisplay.date}
          caption={mediaDisplay.caption}
          onMediaDisplay={closeMediaPopup}
        />
      )}
    </section>
  );
}
