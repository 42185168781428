import { useLocations } from "../contexts/LocationsContext";
import { useStories } from "../contexts/StoriesContext";
import { useEffect, useState, useCallback } from "react";
import Spinner from "./Spinner";
import { useParams, useNavigate } from "react-router-dom";

import {
  StandaloneSearchBox,
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  InfoWindowF,
  Autocomplete,
} from "@react-google-maps/api";
import { Dialog } from "../components/Dialog";
const mapCenter = { lat: 44.9591, lng: -89.6301 };

export function LocationListStory() {
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const [selectedMarker, setSelectedMarker] = useState("");
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [delID, setDelID] = useState(null);
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const params = useParams();
  console.log(params.id);

  const { getStory, currentStory, storyIsLoading } = useStories();
  const [storyID, setStoryID] = useState(Number(params.id));

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [storyTitle, setStoryTitle] = useState();
  const [storyText, setStoryText] = useState();
  const [accessLevel, setAccessLevel] = useState(2);
  const [tagList, setTagList] = useState([]);

  const [storyLocations, setStoryLocations] = useState([]);
  const [storyPins, setStoryPins] = useState([]);
  const [loading, setLoading] = useState(false);

  //tagtype     1: story,     2: location,    3: memory
  const tagtype = 1;

  const onDelete = useCallback(
    (tagIndex) => {
      setTagList(tagList.filter((_, i) => i !== tagIndex));
    },
    [tagList]
  );

  const onAddition = useCallback(
    (newTag) => {
      setTagList([...tagList, newTag]);
    },
    [tagList]
  );

  const setMapCenter = (myLat, myLng) => {
    let mapCenter = {
      lat: `${myLat}`,
      lng: `${myLng}`,
    };
    console.log("mapCenter " + mapCenter.lat);
  };

  const loadStory = async () => {
    await getStory(storyID);

    //Update State...
    setStoryID(storyID);
    setStartDate(currentStory.StartDate);
    setEndDate(currentStory.EndDate);
    setStoryTitle(currentStory.StoryTitle);
    setStoryText(currentStory.StoryText);
    setAccessLevel(currentStory.AccessLevel);
    setStoryLocations(currentStory.StoryLocations);
  };

  const loadLocationData = async () => {
    if (currentStory.StoryLocations && currentStory.StoryLocations.length > 0) {
      setLoading(true);
      const storylocIDs = currentStory.StoryLocations;
      fetch(
        `${baseUrl}/stories/locations/${currentStory.CustUUID}?LocIds=${storylocIDs}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setStoryPins(data);
          console.log(data[0].LocLat);
          console.log(data[0].LocLng);
          setMapCenter(data[0].LocLat, data[0].LocLng);
        });
      setLoading(false);
    }
  };

  useEffect(
    function () {
      async function getMapData() {
        //getStory sets currentStory
        setLoading(true);

        await loadStory();
        await loadLocationData();

        setLoading(false);
      }
      getMapData();
    },
    [currentStory]
  );

  //   useEffect(() => {
  //     if (currentStory.StoryLocations && currentStory.StoryLocations.length > 0) {
  //       setLoading(true);
  //       const storylocIDs = currentStory.StoryLocations;
  //       fetch(
  //         `${baseUrl}/stories/locations/${currentStory.CustUUID}?LocIds=${storylocIDs}`,
  //         {
  //           method: "GET",
  //         }
  //       )
  //         .then((res) => res.json())
  //         .then((data) => {
  //           setStoryPins(data);
  //           console.log(data[0].LocLat);
  //           console.log(data[0].LocLng);
  //           setMapCenter(data[0].LocLat, data[0].LocLng);
  //         });
  //       setLoading(false);
  //     }
  //   }, []);

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };
  console.log(mapCenter);

  function handleEditLocation(Location) {
    console.log("Edit " + Location.LocID);
    navigate(`/app/locations/${Location.LocID}`);
  }

  function handleDelete(LocID) {
    setDelID(LocID);
    handleDialog("Are you sure you want to delete?", true);

    //navigate(`/app/story/${story.StoryID}`);
  }
  const areUSureDelete = (choose) => {
    if (choose) {
      //Dsend Del Function
      deleteLocation(delID);
      handleDialog("", false);
      setDelID(null);
    } else {
      handleDialog("", false);
      setDelID(null);
    }
  };

  const { isLoading, deleteLocation } = useLocations();

  function cbDate(mydate) {
    const newDate = new Date(mydate);
    let theDate = newDate.toISOString().split("T")[0];
    return theDate;
  }

  if (isLoading) return <Spinner />;

  if (!isLoaded) return <Spinner />;

  // const markerCoors = {
  //   lat: 39.1031,
  //   lng: -84.512,
  // };
  return (
    <>
      {dialog.isLoading && (
        <Dialog onDialog={areUSureDelete} message={dialog.message} />
      )}
      {storyLocations}

      {!loading ? (
        <GoogleMap
          center={mapCenter}
          zoom={4}
          mapContainerStyle={{
            width: "100%",
            height: "90vh",
          }}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: true,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          {storyPins.map((location) => (
            <MarkerF
              position={{ lat: location.LocLat, lng: location.LocLng }}
              key={location.LocID}
              scale="1.1"
              width={150}
              height={150}
              options={{
                color: "ffffff",
                icon:
                  location.LocType === 1
                    ? "/media/images/icon-map-home.png"
                    : location.LocType === 2
                    ? "/media/images/icon-map-school.png"
                    : location.LocType === 3
                    ? "/media/images/icon-map-work.png"
                    : location.LocType === 4
                    ? "/media/images/icon-map-event.png"
                    : "/media/images/icon-map-std.png",
                scale: 5.05,
                width: 150,
                height: 150,
              }}
              onClick={() => {
                setSelectedMarker(location);
              }}
            >
              {selectedMarker.LocID === location.LocID && (
                <InfoWindowF
                  onCloseClick={() => setSelectedMarker(false)}
                  position={{ lat: location.LocLat, lng: location.LocLng }}
                >
                  <>
                    <h2>{location.LocTitle}</h2>
                    <p>{location.LocAddress}</p>
                    <p>
                      {cbDate(location.StartDate)} - {cbDate(location.EndDate)}
                    </p>
                    <button
                      className="BTN-mapFunction BTN-Edit"
                      onClick={() => {
                        handleEditLocation(location);
                      }}
                    ></button>
                    <button
                      className="BTN-mapFunction BTN-Delete"
                      onClick={() => {
                        handleDelete(location.LocID);
                      }}
                    ></button>
                  </>
                </InfoWindowF>
              )}
            </MarkerF>
          ))}
        </GoogleMap>
      ) : (
        <p>Loading map data...</p>
      )}
    </>
  );
}

export default LocationListStory;
